.buttonAction {
    display: flex;
    align-items: center;
    background: rgba(122, 146, 186, 0.2);
    border: 1px solid #7a92ba;
    border-radius: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #7a92ba;
    padding: 0px 20px;
    cursor: pointer;
    max-height: 45px;
    transition: 0.3s;
    margin-right: 20px;
}

.buttonAction img {
    margin-right: 15px;
}

.main-title {
    font-family: "Abel", sans serif !important;
}

.colorStepButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.buttonAction:hover {
    background: white;
}

.contentBlock {
    margin-top: 0px;
}

.buttonActive {
    background: white;
}

.buttonAction:disabled {
    opacity: 0.5;
}

.addLabelInputs {
    display: block;
    width: 100%;
}

.addLabelInputs input {
    width: 100%;
    box-sizing: border-box;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
}

.saveDesignButton {
    max-width: 220px;
    justify-content: center;
    margin-right: 10px;
}

.addLabelInputs input {
    background: #ececec;
    border: 1px solid #c1c1c1;
    border-radius: 12px;
    height: 45px;
    outline: none;
    padding-left: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #595959;
    margin-left: 0px;
    margin-right: 0px;
}

.colorLabels {
    display: flex;
    max-width: 500px;
    gap: 20px;
}

.colorLabels input {
    margin-bottom: 0;
}

@media (max-width: 490px) {
    .contentButtons {
        display: block;
    }

    .labelsStep h1 {
        font-size: 32px !important;
        line-height: 40px !important;
    }

    .colorStepButtons {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .buttonWithIcon {
        margin-bottom: 20px;
    }

    .labelsStep .buttonWithIcon {
        margin: 0 auto;
    }

    .previewBottom {
        flex-wrap: wrap;
        gap: 20px;
        justify-content: flex-start !important;
    }

    .colorStepButtons .buttonWithIcon {
        margin-bottom: 0;
        margin: 0;
    }
}
