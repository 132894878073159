.colorPicker .colorCircle {
    opacity: 0.5;
}

.colorPicker {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

.colorCircle {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    cursor: pointer;
    border: 2px solid rgba(255, 255, 255, 0);
}

.activeColor {
    border: 2px solid rgba(255, 255, 255, 1);
    opacity: 0.8 !important;
}

.picker-menu {
    width: 300px !important
}
