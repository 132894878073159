.mergeActiveLabel {
  background: rgba(0, 0, 0, 0.5);
  inset: -1px !important;
}

.mergeActiveLabelP {
  color: white;
}

.mergeSelectMark {
  display: none;
  width: 30px;
  height: 30px;
}

.labelCircle {
  text-align: center;
  padding-top: 8px;
  box-sizing: border-box;
}

.mergeActiveLabelMergeSelectMark {
  display: block !important;
}

.mergeActiveLabelCircle {
  background: rgba(0, 16, 34, 0.45);
}

.mergeLabel {
  cursor: pointer;
}

.bottomLabel {
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.topLabel {
  min-height: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-block-end: 0px !important;
}

.labelHorCenter {
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.labelHorCenterBottom {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.labelItem {
  position: relative;
}

.pointerNone {
  pointer-events: none;
}

.doubleLabelImage {
  width: 350px !important;
  position: relative;
  left: 1px;
  bottom: 0px;
  z-index: 10;
  pointer-events: none;
}

.transparent {
  opacity: 0;
}

.doubleLabel {
  width: 175px;
  height: 275px;
}

.doubleLabel:first-child {
  width: 220px;
}

.doubleLabel:last-child {
  width: 220px;
}

.fakeLabel {
  pointer-events: none;
}

.hideLabel {
  display: none !important;
}
.samsonTopBigLabel {
  top: 5px !important;
}

.samsonBottomBigLabel {
  bottom: 8px !important;
}

.samsonTopLabelFirstRow {
  margin-top: 2px;
}

.samsonBottomLabelFirstRow {
  margin-top: 10px;
  min-height: 12px !important;
}

.samsonTopLabelSecondRow {
  margin-top: 19px;
  margin-bottom: 15px;
}

.samsonBottomLabelSecondRow {
  margin-top: 0px;
}

.behringerBottomBigLabel {
  bottom: -3px !important;
}

.behringerTopBigLabel {
  top: 3px !important;
}

.behringerTopLabelFirstRow {
  margin-top: 18px;
  margin-bottom: 7px;
}

.behringerBottomLabelFirstRow {
  margin-top: 3px;
  min-height: 12px !important;
}

.behringerTopLabelSecondRow {
  margin-top: 2px;
  margin-bottom: 4px;
}

.behringerBottomLabelSecondRow {
  margin-top: 10px;
}

.behringerCircleBottom {
  margin-bottom: 11px;
}

.behringerBottomCenterLabel {
  padding-top: 10px;
}

.dbxBottomBigLabel {
  bottom: 2px !important;
}

.dbxTopBigLabel {
  top: -4px !important;
}

.dbxTopLabelFirstRow {
  margin-top: 13px;
  margin-bottom: 11px;
}

.dbxBottomLabelFirstRow {
  margin-top: 1px;
  min-height: 12px !important;
}

.dbxTopLabelSecondRow {
  margin-top: -1px;
  margin-bottom: 2px;
}

.dbxBottomLabelSecondRow {
  margin-top: 8px;
}

.neutrikBottomBigLabel {
  bottom: 1px !important;
}

.neutrikTopBigLabel {
  top: 18px !important;
}

.neutrikTopLabelFirstRow {
  margin-top: 6px;
  margin-bottom: 6px;
}

.neutrikBottomLabelFirstRow {
  margin-top: 12px;
  min-height: 12px !important;
}

.neutrikTopLabelSecondRow {
  margin-top: 6px;
  margin-bottom: 6px;
}

.neutrikBottomLabelSecondRow {
  margin-top: 12px;
}

.artBottomBigLabel {
  bottom: -8px !important;
}

.artTopBigLabel {
  top: -10px !important;
}

.artTopLabelFirstRow {
  margin-top: 5px;
  margin-bottom: 31px;
}

.artBottomLabelFirstRow {
  margin-top: 2px;
  min-height: 12px !important;
}

.artTopLabelSecondRow {
  margin-top: 0px;
  margin-bottom: 2px;
}

.artBottomLabelSecondRow {
  margin-top: 32px;
}

.artXlrBottomBigLabel {
  bottom: -4px !important;
}

.artXlrTopBigLabel {
  top: -10px !important;
}

.artXlrTopLabelFirstRow {
  margin-top: 8px;
  margin-bottom: 5px;
}

.artXlrBottomLabelFirstRow {
  margin-top: 28px;
  min-height: 12px !important;
}

.blackLionPbrXlrSmallCellWr {
  width: 118.7px !important ;
  height: 279.6px !important ;
}

.blackLionPbrXlrCellWr {
  width: 167.2px;
  height: 279.6px;
}

.blackLionPbrXlrCircle {
  width: 157.2px;
  height: 157.2px;
}

.blackLionPbrXlrOnlyOneLabel {
  margin-top: 130px !important;
}

.mergeSelectMarkBalckLionPbrXlr {
  top: 50px !important;
}
