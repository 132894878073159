.labelItem {
  width: 110px;
  height: 143px;
  background: #f0eef0;
  border-radius: 8px;
  text-align: center;
  gap: 20px;
  flex-wrap: wrap;
  text-align: center;
  border-color: #ffffff;
  border-width: 1px !important;
  border-style: solid;
  box-sizing: border-box;
}

.labelItemBorder {
  border-color: #000000 !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.labelCircle {
  margin: 0 auto;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background: rgba(149, 149, 149, 0.4);
  border: 1px solid rgba(107, 107, 107, 0.5);
}

.samsonCircle {
  width: 73px;
  height: 73px;
}

.behringerCircle {
  width: 79px;
  height: 79px;
}

.dbxCircle {
  width: 84px;
  height: 84px;
}

.neutrikCircle {
  width: 71px;
  height: 71px;
}

.artCircle {
  width: 70px;
  height: 70px;
}

.samsonLabel {
  margin-top: 24px !important;
}

.behringerLabel {
  margin-top: 28px !important;
}

.dbxLabel {
  margin-top: 24px !important;
}

.neutrikLabel {
  margin-top: 36px !important;
}

.artLabel {
  margin-top: 38px !important;
}

.topLabel {
  font-family: "Open Sans", sans-serif;
  font-size: 12px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  height: 17px;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
  color: #bababa;
  margin-block-end: 0px !important;
  margin-top: 0;
  margin-right: 6px;
  margin-left: 6px;
}

.bottomLabel {
  font-family: "Open Sans", sans-serif;
  font-size: 12px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  height: 17px;
  margin: 0 auto;
  margin-bottom: 5px;
  cursor: pointer;
  color: #bababa;
}

.changedLabel {
  font-size: 12px;
  color: black;
}

.activeLabel {
  background: rgba(0, 0, 0, 0.509) !important;
  inset: -1px !important;
}

.activeLabelFake .labelCircle {
  background: #959395;
}

.activeLabelFake p {
  color: black;
}

.middleLine {
  /* position: relative; */
}

.middleLine {
  width: 55%;
  height: 20px;
  background: #c1bfbf;
  border-radius: 18px;
  bottom: -10px;
  left: 15%;
  z-index: 1;
  position: absolute;
  border: 2px solid white;
  box-sizing: border-box;
}

.doubleLabelImage {
  width: 350px !important;
  position: relative;
  left: 1px;
  bottom: 0px;
  z-index: 10;
  pointer-events: none;
}

.transparent {
  opacity: 0;
}

.doubleLabel {
  width: 175px;
  height: 275px;
}

.doubleLabel:first-child {
  width: 220px;
}

.doubleLabelImage:first-child {
  left: 45px;
}

.doubleLabel:last-child {
  width: 220px;
}

.bigLabel {
  width: 200px;
}

.bigLabel {
  margin: 0;
  padding-right: 15px;
}

.bigLabel .labelCircle {
  float: right;
}

.bigLabel .topLabel {
  text-align: right;
}

.centeredLabel {
  text-align: center !important;
}

.onlyOneLabel>.labelCircle {
  display: none !important;
}

.onlyOneLabelInput {
  font-size: 14px;
  margin-top: 60px !important;
}

#oneLabelInput {
  font-size: 16px;
  font-weight: 600;
}

.fakeLabel>* {
  display: none !important;
}

.fakeLabel>.mergeActiveLabel {
  display: block !important;
}

.fakeLabel {
  width: 35px;
  position: relative;
  left: 0px;
  background: white;
}

.cellLabelInput {
  font-family: "Open Sans", sans-serif;
  outline: none;
  outline-width: 0px;
  outline-offset: 0px;
  border-color: transparent;
  border-width: 0px;
  background-color: transparent;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  height: 17px;
  padding: 0;
  font-size: 12px;
  display: block;
}

.cellLabelInput:focus::placeholder {
  color: transparent;
}

.cellLabelInput-black::placeholder {
  color: #000000;
}

.cellLabelInput-white::placeholder {
  color: #ffffff;
}

.labelHorCenter:focus::placeholder {
  color: transparent;
}

.labelHorCenterBottom:focus::placeholder {
  color: transparent;
}

.activeLabelBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.middleLabel {
  position: absolute;
  left: 40px;
  top: 40px;
}

.doubleTemplateBottomLabel {
  margin-top: 0px;
}

.topLabelWithoutMiddleLine {
  margin-top: 36px;
}

.topBottomSameMarginsTopCell {
  margin-top: 2px;
}

.topBottomSameMarginsBottomCell {
  margin-top: 2px;
}

.topLabelWithMiddleLine {
  margin-top: 35px;
}

.largeTemplateLabel {
  margin-right: -47px;
}

.largeTemplateLabelFirstItems {
  margin-right: 28px !important;
}

.largeTemplateLabelWr {}

.mirroredLargeItemCircle {
  float: left !important;
  margin-left: 15px;
}

.mirroredLargeItemMiddleLabel {
  margin-left: 100px;
}

.mirroredLargeItemLabel {
  text-align: center !important;
  margin-left: -36px !important;
}

.artWiderItemLeft {
  padding-left: 16px;
}

.artWiderItemRight {
  padding-right: 16px;
}

.samsonTopBigLabel {
  top: 5px !important;
}

.samsonBottomBigLabel {
  bottom: 8px !important;
}

.samsonTopLabelFirstRow {
  margin-top: 2px;
}

.samsonBottomLabelFirstRow {
  margin-top: 10px;
  min-height: 12px !important;
}

.samsonTopLabelSecondRow {
  margin-top: 19px;
  margin-bottom: 15px;
}

.samsonBottomLabelSecondRow {
  margin-top: 0px;
}

.behringerBottomBigLabel {
  bottom: 8px !important;
}

.behringerTopBigLabel {
  top: 18px !important;
}

.behringerTopLabelFirstRow {
  margin-top: 21px;
  margin-bottom: 7px;
}

.behringerBottomLabelFirstRow {
  margin-top: 3px;
  min-height: 12px !important;
}

.behringerTopLabelSecondRow {
  margin-top: 2px;
  margin-bottom: 4px;
}

.behringerBottomLabelSecondRow {
  margin-top: 10px;
}

.behringerCircleBottom {
  margin-bottom: 11px;
}

.dbxBottomBigLabel {
  bottom: 14px !important;
}

.dbxTopBigLabel {
  top: 18px !important;
}

.dbxTopLabelFirstRow {
  margin-top: 13px;
  margin-bottom: 11px;
}

.dbxBottomLabelFirstRow {
  margin-top: 1px;
  min-height: 12px !important;
}

.dbxTopLabelSecondRow {
  margin-top: -1px;
  margin-bottom: 2px;
}

.dbxBottomLabelSecondRow {
  margin-top: 8px;
}

.neutrikBottomBigLabel {
  bottom: 3px !important;
}

.neutrikTopBigLabel {
  top: 18px !important;
}

.neutrikTopLabelFirstRow {
  margin-top: 6px;
  margin-bottom: 6px;
}

.neutrikBottomLabelFirstRow {
  margin-top: 12px;
  min-height: 12px !important;
}

.neutrikTopLabelSecondRow {
  margin-top: 6px;
  margin-bottom: 6px;
}

.neutrikBottomLabelSecondRow {
  margin-top: 12px;
}

.artBottomBigLabel {
  bottom: -8px !important;
}

.artTopBigLabel {
  top: -10px !important;
}

.artTopLabelFirstRow {
  margin-top: 5px;
  margin-bottom: 33px;
}

.artBottomLabelFirstRow {
  margin-top: 2px;
  min-height: 12px !important;
}

.artTopLabelSecondRow {
  margin-top: 0px;
  margin-bottom: 2px;
}

.artBottomLabelSecondRow {
  margin-top: 35px;
}

.artXlrBottomBigLabel {
  bottom: -8px !important;
}

.artXlrTopBigLabel {
  top: -10px !important;
}

.artXlrTopLabelFirstRow {
  margin-top: 8px;
  margin-bottom: 5px;
}

.artXlrBottomLabelFirstRow {
  margin-top: 28px;
  min-height: 12px !important;
}

.blackLionPbrXlrSmallCellWr {
  width: 118.7px !important;
  height: 279.6px !important;
}

.blackLionPbrXlrCellWr {
  width: 167.2px;
  height: 279.6px;
}

.blackLionPbrXlrCircle {
  width: 157.2px;
  height: 157.2px;
}

.blackLionPbrXlrTopLabelFirstRow {
  margin-top: 25px;
  margin-bottom: 25px;
}

.blackLionPbrXlrBottomLabelFirstRow {
  margin-top: 18px;
}

.blackLionPbrXlrOnlyOneLabel {
  margin-top: 130px !important;
}

.blackLionPbrTrsTrs3TopLabelFirstRow {
  margin-top: 16px;
  margin-bottom: 10px;
}

.blackLionPbrTrsTrs3TopLabelSecondRow {
  margin-top: 3px;
  margin-bottom: 4px;
}

.blackLionPbrTrsTrs3BottomLabelSecondRow {
  margin-top: 11px;
}

.blackLionPbrTrsTrs3CellWr {
  width: 118.3px;
  height: 138.1px;
}

.blackLionPbrTrsTrs3SmallCellWr {
  width: 88.6px !important;
  height: 138.1px !important;
}

.blackLionPbrTrsTrs3BottomBigLabel {
  bottom: -6px !important;
}

.blackLionPbrTrsTrs3BottomLabelFirstRow {
  margin-top: 2px;
}

.blackLionPbrTrsBtTopLabelSecondRow {
  margin-top: 3px;
  margin-bottom: 4px;
}

.blackLionPbrTrsBtBottomLabelFirstRow {
  margin-top: 2px;
}

.blackLionPbrTrsBtBottomLabelSecondRow {
  margin-top: 11px;
}

.blackLionPbrTrsBtCellWr {
  width: 118.3px;
  height: 138.1px;
}

.blackLionPbrTrsBtSmallCellWr {
  width: 88.6px !important;
  height: 138.1px !important;
}

.blackLionPbrTrsBtBottomBigLabel {
  bottom: -4px !important;
}

.blackLionPbrTrsBtTopLabelFirstRow {
  margin-top: 16px;
  margin-bottom: 10px;
}

.blackLionPbrTrsBtPreLastInput {
  margin-top: 7px;
}

.blackLionPbrTrsBtRectangle {
  border-radius: 10px !important;
  border-bottom-width: 0px !important;
  position: absolute;
  background-color: rgba(149, 149, 149, 0.4);
  border: 1px solid rgba(107, 107, 107, 0.5);
  left: 50%;
  transform: translateX(-50%);
  top: 42px;
  width: 70px !important;
  height: 200px !important;
  z-index: 10;
}

.blackLionPbrTrsBtBottomLabelWrMargin {
  margin-top: 108px;
}

.blackLionPbrTrsBtRectangleFirstRow {
  /* border-top-left-radius: 10px !important;
  border-top-right-radius:10px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-width: 0px !important;
  height: 98px !important; */
  display: none;
}

.blackLionPbrTrsBtRectangleSecondRow {
  /* border-top-left-radius: 0px !important;
  border-top-right-radius:0px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-width: 0px !important;
  height: 104px !important; */
  display: none;
}
