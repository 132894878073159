nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav img {
  cursor: pointer;
}

.menuIcons {
  display: flex;
}

.menuIcons img {
  margin-left: 10px;
}

.stepsList {
  max-width: 450px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 30px;
  gap: 10px;
}

.stepsList p {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 90%;
  color: #6b6b6b;
  text-align: center;
  padding: 10px 15px;
  background: #3f80f115;
  border: 1px solid #3f80f1;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.3s;
}

.stepsList p:hover {
  color: white;
  background: #3f80f1;
  border: 1px solid #3f80f1;
}

.stepsListActive {
  color: white !important;
  background: #3f80f1 !important;
  border: 1px solid #3f80f1 !important;
}

.manuCart {
  position: relative;
}

.cartIndicator {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #3f80f1;
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.logoIcon {
  width: 160px;
  margin-top: 10px;
}

.logoMobileIcon {
  width: 40px;
}

@media (max-width: 500px) {
  .stepsList p {
    font-size: 14px;
    padding: 9px 5px;
  }
}

@media (max-width: 400px) {
  .stepsList p {
    font-size: 12px;
    padding: 9px 6px;
  }
}
