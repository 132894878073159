* {
  font-family: "Abel", sans-serif;
  -webkit-tap-highlight-color: transparent;
}

p {
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
  margin-top: 12px;
  margin-bottom: 12px;
}

.wrapper {
  max-width: 1325px;
  margin: 0 auto;
  margin-top: 10px;
}

.contentPart {
  height: calc(100vh - 250px);
  margin-top: 20px;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
/* FONTS */

@font-face {
  font-family: "Arial Regular";
  src: url("../src/static/fonts/ArialMT.eot");
  src: url("../src/static/fonts/ArialMT.eot?#iefix") format("embedded-opentype"), url("../src/static/fonts/ArialMT.woff") format("woff"),
    url("../src/static/fonts/ArialMT.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* POPUP */

.popup {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupBlock {
  background: rgba(51, 51, 51, 0.94);
  backdrop-filter: blur(2px);
  border-radius: 12px;
  padding: 20px 24px;
  min-width: 300px;
  z-index: 999;
  position: relative;
}

.popupClose {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popupClose p {
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
}

.popupClose img {
  cursor: pointer;
  opacity: 0.5;
  transition: 0.3s;
}

.popupClose img:hover {
  opacity: 1;
}

:root {
  --rc-drag-handle-size: 12px;
  --rc-drag-handle-mobile-size: 24px;
  --rc-drag-handle-bg-colour: rgba(0, 0, 0, 0.2);
  --rc-drag-bar-size: 6px;
  --rc-border-color: rgba(255, 255, 255, 0.7);
  --rc-focus-color: #0088ff;
}

.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  max-width: 100%;
}

.ReactCrop *,
.ReactCrop *:before,
.ReactCrop *:after {
  box-sizing: border-box;
}

.ReactCrop--disabled,
.ReactCrop--locked {
  cursor: inherit;
}

.ReactCrop__child-wrapper {
  overflow: hidden;
  max-height: inherit;
}

.ReactCrop__child-wrapper > img,
.ReactCrop__child-wrapper > video {
  display: block;
  max-width: 100%;
  max-height: inherit;
}

.ReactCrop:not(.ReactCrop--disabled) .ReactCrop__child-wrapper > img,
.ReactCrop:not(.ReactCrop--disabled) .ReactCrop__child-wrapper > video {
  touch-action: none;
}

.ReactCrop:not(.ReactCrop--disabled) .ReactCrop__crop-selection {
  touch-action: none;
}

.ReactCrop__crop-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(0);
  cursor: move;
}

.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}

.ReactCrop--circular-crop .ReactCrop__crop-selection {
  border-radius: 50%;
}

.ReactCrop--circular-crop .ReactCrop__crop-selection:after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border: 1px solid var(--rc-border-color);
  opacity: 0.3;
}

.ReactCrop--no-animate .ReactCrop__crop-selection {
  outline: 1px dashed white;
}

.ReactCrop__crop-selection:not(.ReactCrop--no-animate .ReactCrop__crop-selection) {
  animation: marching-ants 1s;
  background-image: linear-gradient(to right, #fff 50%, #444 50%), linear-gradient(to right, #fff 50%, #444 50%),
    linear-gradient(to bottom, #fff 50%, #444 50%), linear-gradient(to bottom, #fff 50%, #444 50%);
  background-size: 10px 1px, 10px 1px, 1px 10px, 1px 10px;
  background-position: 0 0, 0 100%, 0 0, 100% 0;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  color: #fff;
  animation-play-state: running;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes marching-ants {
  0% {
    background-position: 0 0, 0 100%, 0 0, 100% 0;
  }

  to {
    background-position: 20px 0, -20px 100%, 0 -20px, 100% 20px;
  }
}

.ReactCrop__crop-selection:focus {
  outline: 2px solid var(--rc-focus-color);
  outline-offset: -1px;
}

.ReactCrop--invisible-crop .ReactCrop__crop-mask,
.ReactCrop--invisible-crop .ReactCrop__crop-selection {
  display: none;
}

.ReactCrop__rule-of-thirds-vt:before,
.ReactCrop__rule-of-thirds-vt:after,
.ReactCrop__rule-of-thirds-hz:before,
.ReactCrop__rule-of-thirds-hz:after {
  content: "";
  display: block;
  position: absolute;
  background-color: #fff6;
}

.ReactCrop__rule-of-thirds-vt:before,
.ReactCrop__rule-of-thirds-vt:after {
  width: 1px;
  height: 100%;
}

.ReactCrop__rule-of-thirds-vt:before {
  left: 33.3333333333%;
}

.ReactCrop__rule-of-thirds-vt:after {
  left: 66.6666666667%;
}

.ReactCrop__rule-of-thirds-hz:before,
.ReactCrop__rule-of-thirds-hz:after {
  width: 100%;
  height: 1px;
}

.ReactCrop__rule-of-thirds-hz:before {
  top: 33.3333333333%;
}

.ReactCrop__rule-of-thirds-hz:after {
  top: 66.6666666667%;
}

.ReactCrop__drag-handle {
  position: absolute;
  width: var(--rc-drag-handle-size);
  height: var(--rc-drag-handle-size);
  background-color: var(--rc-drag-handle-bg-colour);
  border: 1px solid var(--rc-border-color);
}

.ReactCrop__drag-handle:focus {
  background: var(--rc-focus-color);
}

.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  cursor: nw-resize;
}

.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: n-resize;
}

.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: ne-resize;
}

.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  cursor: e-resize;
}

.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
  cursor: se-resize;
}

.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  cursor: s-resize;
}

.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  transform: translate(-50%, 50%);
  cursor: sw-resize;
}

.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  cursor: w-resize;
}

.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}

.ReactCrop__drag-bar {
  position: absolute;
}

.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: var(--rc-drag-bar-size);
  transform: translateY(-50%);
}

.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: var(--rc-drag-bar-size);
  height: 100%;
  transform: translate(50%);
}

.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--rc-drag-bar-size);
  transform: translateY(50%);
}

.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: var(--rc-drag-bar-size);
  height: 100%;
  transform: translate(-50%);
}

.ReactCrop--new-crop .ReactCrop__drag-bar,
.ReactCrop--new-crop .ReactCrop__drag-handle,
.ReactCrop--fixed-aspect .ReactCrop__drag-bar,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}

@media (pointer: coarse) {
  .ReactCrop .ord-n,
  .ReactCrop .ord-e,
  .ReactCrop .ord-s,
  .ReactCrop .ord-w {
    display: none;
  }

  .ReactCrop__drag-handle {
    width: var(--rc-drag-handle-mobile-size);
    height: var(--rc-drag-handle-mobile-size);
  }
}

.cropp-height {
  height: 100%;
}

.cropp-height > .ReactCrop__child-wrapper {
  height: 100%;
}

.cropp-width {
  width: 100%;
}

.cropp-width > .ReactCrop__child-wrapper {
  width: 100%;
}

/*

font-family: 'Alfa Slab One', cursive;
font-family: 'Antonio', sans-serif;
font-family: 'Bangers', cursive;
font-family: 'Benne', serif;
font-family: 'BioRhyme', serif;

*/

.arial {
  font-family: "Arial Regular";
}

.alfaSlabOne {
  font-family: "Alfa Slab One", cursive;
}

.antonio {
  font-family: "Antonio", sans-serif;
}

.bangers {
  font-family: "Bangers", cursive;
}

.benne {
  font-family: "Benne", serif;
}

.bioRhyme {
  font-family: "BioRhyme", serif;
}

.openSans {
  font-family: "Open Sans", sans-serif;
}

.robotoMono {
  font-family: "Roboto Mono", monospace;
}

.robotoSlab {
  font-family: "Roboto Slab", serif;
}

.ptSerif {
  font-family: "PT Serif", serif;
}

.caveat {
  font-family: "Caveat", serif;
}

@media (max-width: 1340px) {
  .wrapper {
    width: 90%;
  }
  .templatesList {
    max-width: initial;
  }
  .bottomMenu {
    margin-top: 50px;
  }
  .contentPart {
    height: auto;
    margin-top: 20px;
  }
}

.loginPage {
  max-width: 400px;
  width: 90%;
  margin: 0 auto;
  padding-top: 10vh;
}

.loginPage h1 {
  color: #131313;
  font-size: 42px;
  font-weight: 300;
  margin-bottom: 20px;
}

.loginSubtitle {
  color: #000;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 30px;
}

.loginInput {
  display: grid;
  margin-bottom: 20px;
}

.loginInput input {
  border-radius: 12px;
  border: 1px solid #c1c1c1;
  background: #ececec;
  padding: 10px 20px;
  box-sizing: border-box;
  outline: none;
}

.loginInput label {
  margin-bottom: 5px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.loginButton {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  width: 90%;
  height: 50px;
  border-radius: 12px;
  border: 1px solid #7a92ba;
  background: #7a92ba;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 30px;
}

.loginButton p {
  font-size: 20px;
  color: white;
}

.loginButton img {
  width: 20px;
  position: relative;
  top: 2px;
  margin-left: 10px;
}

.loginBottomText {
  text-align: center;
}

.loginBottomText a {
  color: #7595ec;
  font-size: 14px;
  text-decoration: none;
}

.admin {
  display: flex;
  gap: 50px;
  justify-content: space-between;
}

.blackLionPbrXlrCellWr {
  width: 167.2px;
  height: 279.6px;
}

@media (max-width: 768px) {
  .admin {
    flex-direction: column;
    gap: 20px;
    padding-bottom: 75px;
  }
}

.myDesigns a {
  text-decoration: none;
}

.stepTexts {
}

/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 490px) {
  .loginButton {
    min-width: 100%;
  }
  .labelsStep h1 {
    font-size: 34px;
    line-height: 45px;
  }
  .contentButtons {
    min-height: 60px;
    margin-top: 10px;
  }
  .templatesList {
    margin-top: 20px;
  }
}

.labelNumber {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1000;
  color: black !important;
  font-size: 14px;
  font-weight: 600;
  width: 34px;
  height: 34px;
  border: 1px solid #c1c1c1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  bottom: -40px !important;
  border-radius: 100px;
}

.LabelNumberTop {
  top: -45px;
}

.LabelNumberBottom {
  bottom: -45px !important;
}

.labelsItemsList {
  transition: 0.3s;
}

.labelsDark {
  filter: brightness(0.5);
  pointer-events: none;
}

.labelsPreview {
  pointer-events: none;
}

.EditHide .Edit {
  opacity: 0;
}

.previewImageWrapper {
  width: 100%;
  padding-top: 100px;
}

.previewImageWrapper img {
  width: 100%;
}

.labelsStep h1 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 300;
  font-size: 42px;
  line-height: 57px;
  text-align: center;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
}

.labelsStep span {
  color: #337eff;
}

.labelsStep {
  text-align: center;
}

.contentButtons {
  text-align: left;
  align-items: center;
}

.optionalText {
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #6c6c6c;
}

.previewButtons {
  margin-top: 200px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.fixButton img:last-child {
  position: relative;
  right: 15px;
  bottom: 2px;
  left: 0;
}

.fixButton > div {
  display: flex;
  gap: 5px;
}

.fixButton {
  padding-right: 10px;
}

.previewSaveButton {
  margin-top: 40px;
}

.designGone {
  display: flex;
  justify-content: center;
  padding-top: 200px;
}

.designGone p {
  font-size: 18px;
}

@media (max-width: 490px) {
  .finalStep .stepTexts {
    height: 150px;
  }
  .previewImageStep {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
