.myDesignsHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D1D1D1;
}

.myDesignsHeader p {
    color: #000;
    font-size: 28px;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 15px;
}

.myDesignsHeader img {
    cursor: pointer;
}

.myDesigns {
    padding-bottom: 40px;
}

.myDesigns a {
    text-decoration: none;
    display: contents;
}