.modelSelect {
    margin-top: 60px;
    text-align: center;
}

.modelSelect h1 {
    font-weight: 300;
    font-style: normal;
    font-weight: 300;
    font-size: 42px;
    line-height: 57px;
    text-align: center;
    color: #131313;
    margin-bottom: 0;
}

.modelSelect h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #000000;
}

.templatesList {
    max-width: 780px;
    margin: 0 auto;
}

.template img {
    width: 100%;
}

.template {
    position: relative;
    cursor: pointer;
    margin-bottom: 20px;
}

.template h3 {
    position: absolute;
    left: 0;
    top: -5px;
    right: 0;
    bottom: 0;
    z-index: 2;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #000000;
}

.template {
    transition: 0.3s;
    position: relative;
}

.templateFilter {
    background: #00000094;
    transition: all 0.3s ease-in-out;
    width: 100%;
    height: 94%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.template:hover .templateFilter {
    opacity: 1;
}

.template h3 {
    opacity: 0;
    transition: 0.3s;
    color: white;
}

.template:hover h3 {
    opacity: 1;
}

.template img {
    height: 100%;
}

@media (max-width: 1240px) {
    .template h3 {
        line-height: 63px;
    }
}

@media (max-width: 940px) {
    .template h3 {
        line-height: 43px;
    }
}

@media (max-width: 768px) {
    .template h3 {
        line-height: 23px;
    }
}

@media (max-width: 600px) {
    .template h3 {
        line-height: 13px;
    }
}

@media (max-width: 490px) {
    .template h3 {
        line-height: 5px;
    }
}

@media (max-width: 390px) {
    .template h3 {
        line-height: 0px;
    }
}
