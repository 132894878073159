.adminBlock {
    text-align: left;
}

.adminLink {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    transition: 0.3s;
}

.adminLink:hover {
    background: #d3ddef;
}

.adminLink img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.adminBlock {
    margin-top: 40px;
}

.adminLink {
    margin-bottom: 10px;
    cursor: pointer;
}

.adminLink p {
    font-size: 16px;
    color: #000;
    font-weight: 600;
}

.adminMenu {
    min-width: 275px;
}

.adminLinkActive {
    background: #d3ddef;
}

.adminMenu a {
    text-decoration: none;
}
