.colorPicker .colorCircle {
    opacity: 0.5;
}

.colorPicker {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

.colorCircle {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    cursor: pointer;
    border: 2px solid rgba(255, 255, 255, 0);
}

.activeColor {
    border: 2px solid rgba(255, 255, 255, 1);
    opacity: 0.8 !important;
}

.popupBlock {
    max-width: 375px;
    outline: none;
}

.popupLogoContent {
    text-align: left;
}

.popupContent p,
label {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
}

.popupContent label {
    color: #2F445B;
}

.logoTitle {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 25px;
    color: #FFFFFF;
    margin: 0;
    margin-bottom: 6px;
}

label {
    user-select: none;
}

.custom-logo-wr {
    width: 100%;
     height: 250px;
     display: flex;
    justify-content: center;
}

.custom-logo-container {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 20px;
    overflow: hidden;
}

.custom-logo-fake-wr {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.custom-logo-input {
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.upload-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: cover ;
}

.imgBlock {
    margin-top: 10px;
    margin-bottom: 30px;
}

.logoSelectButton:last-child {
    border: 1px solid #727272;
}

.termsCheckbox {
    margin-top: 10px
}

.termsCheckbox input {
    position: relative;
    top: 1px;
    margin-right: 10px;
}

.logoSelectButton {
    display: flex;
    align-items: center;
    max-height: 45px;
}

.logoSelectButtonTransparent {
    border: none;
    display: flex;
    background: transparent;
    color: #727272;
    cursor: pointer;
}

.logoSelectButtons {
    display: flex;
    justify-content: space-between;
}

.logoSelectButton img {
    margin-right: 13px;
}

.logoSelectButtons>p {
    font-size: 14px;
}

.logoSelectButtons {
    margin-top: 30px;
}

.imgBlock img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 12px;
}

.logoSelectButtons input {
    display: none;
}

.popupLogoClose {
    display: flex;
    justify-content: flex-end !important;
}

.imgPlaceholder {
    width: 100%;
    height: 250px;
    background: white;
    border-radius: 12px;
}

.logoDescription {
    color: white;
}

.popupButtons {
    display: flex;
    justify-content: center;
    height: 100%;
}

.logoDescription {
    margin-bottom: 0;
}

.logoSelectButtons {
    justify-content: flex-end;
}

.logoSelectButton:disabled {
    opacity: 0.4;
}

.popupButtons img {
    width: 20px;
    height: 20px;
}

.popupButtons {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
}

.imgBlock {
    position: relative;
}

.termsCheckbox label {
    color: white;
}

.imgBlock {
    cursor: pointer;
}
