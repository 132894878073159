.mergeSelectMark img {
  width: 60px;
  margin: 0 auto;
}

.labelsList {
  margin-top: 20px;
  background: rgba(237, 237, 237, 0.85);
  border-radius: 12px;
  padding: 20px;
  overflow-x: scroll;
}

.labelsList {
  display: flex;
  position: relative;
}

.labelsContent {
  background: #010001;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

.labelsContentWr {
  background-color: white;
  border-radius: 10px;
}

.labelContentContainer {
  padding: 8px;
  margin: 24px;
}

.labelsLeft {
  padding-left: 10px;
  margin-right: 160px;
  width: 0px;
}

.labelsLeftNeutrik {
  margin-right: 0px;
}

.labelsTopItem,
.labelsBottomItem {
  background: #c1bfbf;
  border-radius: 29px;
  width: 57px;
  height: 37px;
}

.labelsLogotype {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  object-fit: cover;
}

.labelsLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 20px 40px;
}

.labelsLogotype {
  margin: auto;
}

.labelsList {
  display: flex;
  overflow-x: auto;
}
.labelsList::-webkit-scrollbar {
  height: 6px !important;
}

.startPaddings {
  padding-left: 150px;
  padding-right: 150px;
}

.labelsCircleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  margin-left: 40px;
}

.labelsCircle {
  min-width: 50px;
  min-height: 50px;
  border-radius: 100%;
  background: #959395;
}

.labelsLeftCircle {
  width: 180px;
}

.mergeSelectMark {
  width: 30px !important;
  margin: 0 auto;
  height: 30px;
}

@media (max-width: 490px) {
  .labelsList {
    box-sizing: border-box;
  }
}

.labelsScrollBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: transparent;
}

.labelsScrollBarInner {
  background: #474747;
  height: 100%;
}

.labelsLogoWrapper {
  width: 102px;
  height: 102px;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}

.logoSelectActive {
  pointer-events: all;
}

.labelsLogotype:hover .labelsPlus {
  opacity: 1;
}

.labelsPlus {
  font-size: 40px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  transition: 0.2s;
  color: black;
  line-height: 60px;
  opacity: 1;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
}

.labelsLogotype {
  position: relative;
  z-index: 1;
}

.labelsPlus:hover {
  opacity: 0;
}

.logoSelected {
  z-index: 99 !important;
}

.labelsCircle {
  margin-right: 20px;
}

@media (max-width: 768px) {
  .labelsScrollBar {
    display: none;
  }
  .labelsList {
    overflow-x: scroll !important;
  }
  .labelsList::-webkit-scrollbar {
    height: 4px !important;
  }
  .contentBlock {
    margin-top: 80px;
  }
  .labelCircle {
    width: 65px;
    height: 65px;
  }
}

.labelsScrollBarWrapper {
  position: relative;
  width: 103%;
  height: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #c1bfbf;
  box-sizing: border-box;
  border-radius: 20px;
}

.dragElement {
  width: 30px;
  height: 100%;
  cursor: grabbing;
  position: absolute;
}

.labelsScrollBarWrapper > div {
  height: 100%;
}

.dragCircle {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: black;
  position: absolute;
  top: -10px;
  left: -1px;
}

.dragCircle svg {
  position: relative;
  top: 5px;
}

.bigLabels .topLabel {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.bigLabels .bottomLabel {
  font-size: 12px !important;
  font-weight: 600 !important;
}

@media (max-width: 768px) {
  .labelsScrollBarWrapper {
    display: none;
  }
  /* .labelsList::-webkit-scrollbar {
        height: 8px !important;
    } */
}
