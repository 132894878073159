.mergeSelectMark {
  display: none;
  width: 30px;
  height: 30px;
}

.labelCircle {
  text-align: center;
  padding-top: 8px;
  box-sizing: border-box;
}

.mergeLabel {
  cursor: pointer;
}

.bottomLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.topLabel {
  min-height: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-block-end: 0px !important;
}

.labelHorCenter {
  font-family: "Open Sans", sans-serif;
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
  margin-top: 12px;
  margin-bottom: 12px;
}

.labelHorCenterBottom {
  font-family: "Open Sans", sans-serif;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
  margin-top: 12px;
  margin-bottom: 12px;
}

.labelItem {
  position: relative;
}

.pointerNone {
  pointer-events: none;
}

.mergeSelectMark {
  position: relative;
  top: 10px;
}
