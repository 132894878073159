.typoSelect p {
    color: #696969;
    cursor: pointer;
    transition: 0.3s;
}

.typoSelect p:hover {
    color: rgba(255, 255, 255, 0.671);
}

.selectedLanguage {
    color: white !important;
}

.typoSelect svg {
    cursor: pointer;
    user-select: none;
}

.selectButton {
    border: 1px solid #DDDDDD;
    border-radius: 12px;
    background: transparent;
    color: #838383;
    padding: 11px 20px;
    transition: 0.3s;
    cursor: pointer;
    font-weight: 400;
}

.selectButton:hover {
    background: #DDDDDD;
    color: black;
}

.popupBottomButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    gap: 20px;
}
