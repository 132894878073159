.labelsStep h1 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    font-size: 42px;
    line-height: 57px;
    text-align: center;
    color: #000000;
    margin-bottom: 0;
}

.labelsStep>h1>span {
    color: #337EFF;
}

.labelsStep {
    text-align: center;
}

.contentButtons {
    text-align: left;
}

.main-title {
    font-family: 'Abel', sans serif !important;
}

.buttonWithIcon {
    display: flex;
    align-items: center;
    background: #7A92BA;
    border: 1px solid #7A92BA;
    border-radius: 12px;
    padding: 5px 20px;
    max-height: 45px;
    cursor: pointer;
    transition: 0.3s;
}

.buttonWithIcon:hover {
    background: #597099;
}

.buttonWithIcon p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
}

.buttonWithIcon img {
    margin-left: 10px;
}

.addLabel {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentButtons {
    display: flex;
    justify-content: space-between;
}

.addLabel input {
    background: #ECECEC;
    border: 1px solid #C1C1C1;
    border-radius: 12px;
    height: 40px;
    outline: none;
    padding-left: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #595959;
    margin-left: 20px;
    margin-right: 20px;
}

.contentButtons>* {
    width: 100%;
}

.addLabel img {
    cursor: pointer;
}

.addLabelInputs {
    display: block;
    width: 100%;
}

.addLabelInputs input {
    width: 100%;
    box-sizing: border-box;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
}

.addLabelInputs {
    max-width: 230px;
    margin-left: 20px;
    margin-right: 20px;
}

.labelsBlock {
    padding: 11px;
    border: 1px solid #333333;
    border-radius: 12px;
    box-sizing: border-box;
    width: 60px;
    height: 36px;
    cursor: pointer;
    transition: 0.3s;
    margin-left: 10px;
    margin-right: 10px;
}

.labelsBlock svg {
    position: relative;
    bottom: 3px;
    right: 2px;
}

.labelsBlock:hover {
    background: #333333;
}

.labelsArrow {
    transition: 0.3s;
}

.labelsBlock:hover .labelsArrow {
    stroke: white !important;
}

@media (max-width: 490px) {
    .labelsIcon {
        margin: 0 auto;
        margin-bottom: 20px;
    }
}
