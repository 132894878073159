.stepButtons {
  display: flex;
  align-items: center;
}

.stepButton {
  border-radius: 12px;
  width: 100px;
  height: 45px;
  display: flex;
  align-items: center;
  border: none;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
}

.stepButton img {
  margin-right: 5px;
}

.stepButton:first-child {
  margin-right: 10px;
}

.stepButton {
  background: #000000;
}

.bottomMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.priceBlock {
  text-align: right;
}

.priceBlock p:first-child {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  color: #000000;
  margin-bottom: 0;
}

.priceBlock p:last-child {
  margin-top: 5px;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: right;
  color: #000000;
}

.stepButton:disabled {
  background: rgba(51, 126, 255, 0.2) !important;
}

.noBottom {
  margin-top: 0;
}
