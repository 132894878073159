.previewBottom {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 30px;
}

.previewBottom button {
    display: flex;
}

.previewButtonTransparent {
    display: flex;
    align-items: center;
    background: transparent;
}

.previewButtonTransparent p {
    color: #7A92BA;
}

.previewButtonTransparent:hover {
    background: transparent;
}

.previewBottom img {
    margin-right: 10px;
}

.previewBottom {
    margin-top: 0px;
}

.finalStep .buttonWithIcon {
    margin: 0;
}

.main-title {
    font-family: 'Abel', sans serif !important;
}

.buttonWithIcon:disabled {
    opacity: 0.6;
}

.contentButtonsFix {
    margin-top: 0px;
    min-height: 50px;
}

.loading {
    width: 20px;
    margin-left: 10px;
    margin-top: 4px;
    position: relative;
    left: 10px;
}

.sharedBottomButtons {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    left: 40px
}

.sharedBottomButtons button {
    display: flex;
    align-items: center;
    border-radius: 3px;
    padding: 8px 12px;
    gap: 6px;
    background: #97A3B8;
    border: none;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
}

.sharedBottomButtons p {
    margin-bottom: 0;
    margin-top: 0;
    color: white;
    font-weight: 600;
}

.sharedBottomButtons img {
    width: 20px;
}

.loadingPreview {
    min-height: 70vh;
    display: flex;
    justify-content: center;
}

.loadingPreview img {
    width: 100px;
}

.previewImageStep {

}

.loadingPreview {
    text-align: center;
    padding-top: 120px;
}
