.labelsStep h1 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    font-size: 42px;
    line-height: 57px;
    text-align: center;
    color: #000000;
    margin-bottom: 0;
    margin-top: 0;
}

.labelsStep span {
    color: #337EFF;
}

.labelsStep {
    text-align: center;
}

.contentButtons {
    text-align: left;
    align-items: center;
}

.main-title {
    font-family: 'Abel', sans serif !important;
}

.optionalText {
    margin-top: 10px;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #6C6C6C;
}

.reverseButton {
    flex-direction: row-reverse;
}

.reverseButton img {
    margin-right: 10px;
    margin-left: 0;
}

.contentButtons {
    margin-top: 40px;
}

.stepTexts {
    min-height: 120px;
}
